import React from "react";

const ButtonFilters = ({ setIsFilterMenuOpened, iconSize = "16px" }) => {
  return (
    <div>
      <button
        className="btn btn-options d-flex gap-2"
        onClick={() => setIsFilterMenuOpened(true)}
      >
        <img
          src="/assets/setting-sliders.svg"
          alt="settings-icon"
          className="marker-icon"
          style={{ width: iconSize }}
        />
        <span className="hide-on-cellphone">Otros filtros</span>
      </button>
    </div>
  );
};

export default ButtonFilters;
